// Copyright 2023 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { TableCell } from '@mui/material';

export function NumHeadCell() {
  return <TableCell width="1px">#</TableCell>;
}

export interface NumContentCellProps {
  readonly num: number;
}

export function NumContentCell({ num }: NumContentCellProps) {
  return (
    <TableCell
      data-testid="commit-num"
      sx={{ fontWeight: 'bold', minWidth: '30px' }}
    >
      {num}.
    </TableCell>
  );
}
